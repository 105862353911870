<template>
  <div class="container" v-if="page">
    <img id="hero" :src="page.image" :alt="page.title" />

    <div class="content">
      <h1>{{ page.title }}</h1>
      <div class="row">
        <div class="col-xs-12 col-md-7">
          <p class="wptext" v-html="content(page.content_html)"></p>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 ">
          <img src="@/assets/images/services.png" alt="Våre tjenester" />
        </div>
      </div>
    </div>
    <div class="hightlighter">
      <div class="content">
        <h2>Lurer du på hvordan skreddersydde gamification-løsninger kan styrke din bedrift eller organisasjon, eller vil du se hva vi kan trylle frem?</h2>
        <router-link to="/kontakt" class="button blue">
          Kontakt oss
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    pageID: "23112"
  }),
  components: {
    Hero: () => import("@/views/components/Hero.vue")
  },
  methods: {
    content(str) {
      str = str.replaceAll("#", "<br>");
      return str;
    }
  },
  computed: {
    page() {
      let pages = this.$store.state.pages;
      let page;

      Object.keys(pages).forEach(key => {
        if (pages[key].id.split("?p=").pop() === this.pageID) {
          page = pages[key];
        }
      });

      return page;
    }
  }
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets/images";
.col2 {
  padding: 60px 20px;

  @media only screen and (min-width: 992px) {
    padding: 0 125px;
  }
}

.map {
  width: 100%;
  height: 300px;
  margin-bottom: 60px;

  @media only screen and (min-width: 62em) {
    height: 500px;
  }
}
</style>
